import * as Animations from './animations'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as Notifications from '@rushplay/notifications'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

const levelColor = {
  error: 'danger',
  info: 'info',
  success: 'success',
  warning: 'warning',
}

const levelIcon = {
  error: Icons.Error,
  info: Icons.Info,
  success: Icons.Success,
  warning: Icons.Warning,
}

function Toast(props) {
  const dispatch = ReactRedux.useDispatch()
  const notification =
    ReactRedux.useSelector((state) =>
      Notifications.getOne(state.notifications, {id: props.id})
    ) || {}
  const translate = Herz.I18n.useTranslate(
    () => [
      `notification.${notification.message}`,
      `notification.${notification.level}.title`,
    ],
    [notification.message, notification.level]
  )

  const Icon = levelIcon[notification.level || 'error']

  return (
    <Animations.BounceInFromRight>
      <ThemeUi.Grid
        sx={{
          gridTemplateColumns: '50px 1fr 50px',
          gridGap: 1,
          bg: 'static-white',
          borderRadius: 1,
          overflow: 'hidden',
          m: 1,
        }}
      >
        <ThemeUi.Grid
          sx={{
            placeItems: 'center',
            backgroundColor: levelColor[notification.level || 'error'],
            fontSize: 4,
            color: 'static-white',
          }}
        >
          <Icon />
        </ThemeUi.Grid>
        <ThemeUi.Grid
          sx={{
            gridGap: '0px',
            fontSize: 1,
            p: 2,
            wordBreak: 'break-word',
            color: 'static-black',
          }}
        >
          <ThemeUi.Heading as="h4" sx={{fontWeight: 'bold'}}>
            {translate(`notification.${notification.level}.title`)}
          </ThemeUi.Heading>
          {translate(
            `notification.${notification.message}`,
            notification.variables
          )}
        </ThemeUi.Grid>
        <ThemeUi.Grid
          onClick={() => dispatch(Notifications.dismiss(props.id))}
          sx={{
            'placeItems': 'center',
            'fontSize': 4,
            'color': 'input-placeholder',
            'transition': 'color 100ms ease-in-out',
            '&:hover': {
              color: 'static-black',
            },
          }}
        >
          <Icons.Close />
        </ThemeUi.Grid>
      </ThemeUi.Grid>
    </Animations.BounceInFromRight>
  )
}

Toast.propTypes = {
  id: PropTypes.number,
}

export function NotificationsContainer() {
  const notifications = ReactRedux.useSelector((state) =>
    Notifications.getAll(state.notifications)
  )

  return (
    <ThemeUi.Box
      sx={{
        position: 'fixed',
        top: '50px',
        right: '0px',
        width: ['100%', 'auto'],
        minWidth: [null, '350px'],
        maxWidth: '420px',
        zIndex: '9999',
        display: 'grid',
      }}
    >
      {notifications.map((id) => (
        <Suspense.Boundary key={id} fallback={null}>
          <Toast id={id} />
        </Suspense.Boundary>
      ))}
    </ThemeUi.Box>
  )
}

export default NotificationsContainer
