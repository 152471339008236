import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as ReactSpring from 'react-spring'
import * as Session from './session'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'
import * as Urql from 'urql'

import {Image} from './image'
import PropTypes from 'prop-types'

function GameTile(props) {
  const transitions = ReactSpring.useTransition(true, null, {
    from: {
      transform: 'scale(0.8)',
      opacity: 0,
    },
    enter: {
      transform: 'scale(1)',
      opacity: 1,
    },
    leave: {
      transform: 'scale(0.8)',
      opacity: 0,
    },
  })
  const backgroundUrl = Hooks.useImgproxyUrl(props.backgroundUrl, {
    extension: 'jpg',
    resizingWidth: 400,
  })

  const backgroundOverlayUrl = Hooks.useImgproxyUrl(
    props.backgroundOverlayUrl,
    {
      extension: 'png',
      resizingWidth: 200,
    }
  )

  const authenticated = ReactRedux.useSelector((state) =>
    Session.isAuthenticated(state.session)
  )

  const gameLink = authenticated ? `/play/${props.gameId}` : '?login=me'

  const overlayPosition =
    props.backgroundOverlayImageAlignment === 'bottom' ? 'bottom' : 'center'

  return transitions.map(
    ({item, key, props: styleProps}) =>
      item && (
        <ThemeUi.Box
          as={ReactSpring.animated.div}
          key={key}
          style={styleProps}
          sx={{
            'backgroundImage':
              'linear-gradient(180deg, #17061D 0%, #291332 50%, #17061D 100%)',
            'backgroundSize': '200% 200%',
            'backgroundPosition': '0% 0%',
            'padding': [1, 2],
            'borderRadius': 1,
            'border': '1px solid #291332',
            'transition': 'background-position 350ms ease-in-out',
            '&:hover': {
              backgroundPosition: '0% 75%',
            },
            '&:active': {
              backgroundPosition: '0% 75%',
            },
          }}
        >
          <ReactRouterDom.Link to={gameLink}>
            <ThemeUi.Box
              sx={{
                'backgroundColor': 'game-tile-background',
                'backgroundImage': `url("${backgroundOverlayUrl}"), url("${backgroundUrl}")`,
                'backgroundSize': 'auto 80%, cover',
                'backgroundRepeat': 'no-repeat, no-repeat',
                'backgroundPosition': `${overlayPosition}, center`,
                'height': ['160px', '240px'],
                'width': '100%',
                'borderRadius': 0,
                'display': 'flex',
                'position': 'relative',
                'justifyContent': 'center',
                'alignItems': 'flex-end',
                'userSelect': 'none',
                '@media screen and (max-width: 350px)': {
                  height: '140px',
                },
              }}
            >
              {props.titleUrl ? (
                <Image
                  extension="png"
                  resizingWidth={200}
                  alt={props.gameKey}
                  source={props.titleUrl}
                  height="140"
                  width="200"
                  sx={{
                    maxWidth: '200px',
                    maxHeight: '80%',
                    width: '100%',
                    height: 'auto',
                    mb: [1, 2],
                    wordBreak: 'break-all',
                  }}
                />
              ) : (
                <ThemeUi.Box
                  sx={{
                    color: 'text',
                    fontWeight: 'bold',
                    fontSize: 3,
                    textAlign: 'center',
                    p: 2,
                  }}
                >
                  {props.title}
                </ThemeUi.Box>
              )}
            </ThemeUi.Box>
          </ReactRouterDom.Link>
        </ThemeUi.Box>
      )
  )
}

GameTile.propTypes = {
  backgroundOverlayImageAlignment: PropTypes.string,
  backgroundOverlayUrl: PropTypes.string,
  backgroundUrl: PropTypes.string,
  gameId: PropTypes.string,
  titleUrl: PropTypes.string,
  title: PropTypes.string,
  gameKey: PropTypes.string,
}

const getCasinoSection = `
  query CasinoLoadMoreSection($id: ID!, $limit: Int!, $offset: Int!) {
    catalogSection(id: $id, limit: $limit, offset: $offset) {
      id
      games {
        backgroundOverlayImageAlignment
        backgroundOverlayUrl
        backgroundUrl
        tags
        titleUrl
        title
        id
        key
      }
    }
  }
`

function loadMoreGamesResolver(prevData, newData) {
  const normalizedGames = R.flatten(
    R.prepend(
      R.pathOr([], ['catalogSection', 'games'], prevData),
      R.pathOr([], ['catalogSection', 'games'], newData)
    )
  )

  return R.assocPath(['catalogSection', 'games'], normalizedGames, newData)
}

function CasinoGridSection(props) {
  const [response, fetchMore] = Hooks.usePaginatedQuery({
    query: getCasinoSection,
    variables: {id: props.id, limit: 19, offset: R.length(props.games)},
    resolver: loadMoreGamesResolver,
  })
  const translate = Herz.I18n.useTranslate()
  const sectionGames = R.pathOr([], ['catalogSection', 'games'], response.data)
  const games = R.flatten(R.prepend(props.games, sectionGames))

  if (response.error && R.isEmpty(games)) {
    return (
      <ThemeUi.Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          gridGap: 1,
          fontSize: 6,
          flex: 1,
        }}
      >
        <Icons.Error />
        <ThemeUi.Box
          sx={{
            fontSize: 2,
          }}
        >
          {translate('errors.casino-section.loading.failed')}
        </ThemeUi.Box>
      </ThemeUi.Box>
    )
  }

  if (response.fetching && R.isEmpty(games)) {
    return (
      <ThemeUi.Grid
        sx={{
          placeItems: 'center',
          flex: 1,
        }}
      >
        <ThemeUi.Spinner />
      </ThemeUi.Grid>
    )
  }

  if (R.isEmpty(games)) {
    return null
  }

  return (
    <ThemeUi.Box sx={{py: [1, 3]}}>
      <ThemeUi.Grid
        sx={{
          'gridTemplateColumns': [
            'repeat(2, 1fr)',
            'repeat(4, 1fr)',
            'repeat(6, 1fr)',
          ],
          'gridGap': 2,
          'justifyContent': 'center',
          'pb': 3,
          // This was the easiest solution I could think of to make each "layout" work.
          // sorry for the mess!
          '>div:nth-of-type(5n+3)': {
            // Default/Small screen pattern: 1 - 2 - 2 (Starting with 2)
            gridColumn: ['span 2', 'auto'],
          },
          '>div:nth-of-type(6n+5), >div:nth-of-type(6n+6)': {
            // Medium screen pattern: 4 - 2
            gridColumn: [null, 'span 2', 'auto'],
          },
          // Large screen patterns: 2 - 6 - 3 - 6
          '>div:nth-of-type(17n+1), >div:nth-of-type(17n+2)': {
            gridColumn: [null, null, 'span 3'],
          },
          '>div:nth-of-type(17n+9), >div:nth-of-type(17n+10), >div:nth-of-type(17n+11)': {
            gridColumn: [null, null, 'span 2'],
          },
        }}
      >
        {R.map(
          (game) => (
            <Suspense.Boundary key={game.id} fallback={null}>
              <GameTile
                backgroundOverlayImageAlignment={
                  game.backgroundOverlayImageAlignment
                }
                backgroundOverlayUrl={game.backgroundOverlayUrl}
                backgroundUrl={game.backgroundUrl}
                gameId={game.id}
                gameKey={game.key}
                title={game.title}
                titleUrl={game.titleUrl}
              />
            </Suspense.Boundary>
          ),
          games
        )}
      </ThemeUi.Grid>

      {props.totalGamesCount !== R.length(games) && (
        <ThemeUi.Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 3,
          }}
        >
          <ThemeUi.Button
            disabled={response.fetching}
            variant="secondary"
            onClick={fetchMore}
          >
            {translate('casino-category.show-more')}
          </ThemeUi.Button>
        </ThemeUi.Box>
      )}
    </ThemeUi.Box>
  )
}

Herz.I18n.Loader.preload(
  ['errors.casino-section.loading.failed', 'casino-category.show-more'],
  CasinoGridSection
)

CasinoGridSection.defaultProps = {
  games: [],
}

CasinoGridSection.propTypes = {
  id: PropTypes.string,
  games: PropTypes.array,
  totalGamesCount: PropTypes.number,
}

const getCasinoCategory = `
  query CasinoCatalog {
    catalog(key: "main") {
      categories {
        id
        sections {
          id
          totalGamesCount
          games {
            backgroundOverlayImageAlignment
            backgroundOverlayUrl
            backgroundUrl
            titleUrl
            title
            tags
            id
            key
          }
        }
      }
    }
  }
`

export function CasinoCatalog() {
  const [response] = Urql.useQuery({
    query: getCasinoCategory,
  })
  const translate = Herz.I18n.useTranslate()

  if (response.error) {
    return (
      <ThemeUi.Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          gridGap: 1,
          fontSize: 6,
        }}
      >
        <Icons.Error />
        <ThemeUi.Box
          sx={{
            fontSize: 2,
          }}
        >
          {translate('errors.casino-category.loading.failed')}
        </ThemeUi.Box>
      </ThemeUi.Box>
    )
  }

  if (response.fetching) {
    return (
      <ThemeUi.Grid
        sx={{
          placeItems: 'center',
          flex: 1,
        }}
      >
        <ThemeUi.Spinner />
      </ThemeUi.Grid>
    )
  }

  return (
    <Suspense.Boundary>
      <ThemeUi.Box
        sx={{
          mx: 'auto',
          maxWidth: 'container.lg',
          width: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          pb: 4,
        }}
      >
        {R.map(
          (section) => (
            <CasinoGridSection
              key={section.id}
              id={section.id}
              totalGamesCount={section.totalGamesCount}
              games={section.games}
            />
          ),
          R.pathOr([], ['catalog', 'categories', 0, 'sections'], response.data)
        )}
      </ThemeUi.Box>
    </Suspense.Boundary>
  )
}

Herz.I18n.Loader.preload(
  ['errors.casino-category.loading.failed'],
  CasinoCatalog
)
