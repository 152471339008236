import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Session from './session'
import * as ThemeUi from 'theme-ui'

export function Banner() {
  const translate = Herz.I18n.useTranslate()
  const authenticated = ReactRedux.useSelector((state) =>
    Session.isAuthenticated(state.session)
  )

  const mainBg = Hooks.useImgproxyUrl(
    translate('banner.background-image.main'),
    [
      {
        extension: 'jpg',
        resizingHeight: 282,
      },
      {
        extension: 'jpg',
        resizingHeight: 400,
      },
    ],
    'babel-fisk'
  )

  return (
    <ThemeUi.Box
      sx={{
        mx: 'auto',
        maxWidth: 'container.lg',
        width: '100%',
        borderRadius: 2,
      }}
    >
      <ThemeUi.Box
        sx={{
          backgroundImage: [`url("${mainBg[0]}")`, `url("${mainBg[1]}")`],
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          backgroundColor: 'nav-background',
          height: ['282px', '400px'],
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pb: [1, 3],
        }}
      >
        {!authenticated && (
          <ReactRouter.Link to="?register=now">
            <ThemeUi.Button>{translate('banner.cta')}</ThemeUi.Button>
          </ReactRouter.Link>
        )}
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

Herz.I18n.Loader.preload(['banner.background-image.main', 'banner.cta'], Banner)
