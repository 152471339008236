import * as Configuration from './configuration'
import * as Constants from './constants'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Session from './session'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {GamePopup} from './game-popup'

function useGameUrl(gameId) {
  const clientType = ReactRedux.useSelector((state) =>
    Configuration.getClientType(state.configuration)
  )
  const language = ReactRedux.useSelector((state) =>
    Configuration.getLanguage(state.configuration)
  )
  const gameServerUrl = ReactRedux.useSelector((state) =>
    Configuration.getGameServerUrl(state.configuration)
  )
  const dpr = ReactRedux.useSelector((state) =>
    Configuration.getDevicePixleRatio(state.configuration)
  )

  const path = new URL(`/games/${gameId}`, gameServerUrl)
  path.search = new URLSearchParams({
    client_type: clientType,
    language: language || '',
    mode: 'classic',
    env: 'stg',
    brand: 'casinome',
    dpr,
  }).toString()

  return R.equals(clientType, Constants.ClientType.UNKNOWN) ? null : path
}

export function GameLauncher() {
  const ref = React.useRef(null)
  const location = ReactRouterDom.useLocation()
  const history = ReactRouterDom.useHistory()
  const {gameId} = ReactRouterDom.useParams()
  const authenticated = ReactRedux.useSelector((state) =>
    Session.isAuthenticated(state.session)
  )

  if (!authenticated) {
    history.push('/?login=me')
  }

  const gameUrl = useGameUrl(gameId)

  React.useEffect(() => {
    function gameEventListener(event) {
      const action = event.data || {}

      switch (action.type) {
        case '@rushplay/gamer/GAME_CLOSED': {
          return history.push('/')
        }
      }
    }

    window.addEventListener('message', gameEventListener)
    return () => {
      window.removeEventListener('message', gameEventListener)
    }
  }, [location, history])

  return (
    <>
      <ThemeUi.Box
        as="iframe"
        sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
        src={gameUrl}
        ref={ref}
        sx={{
          width: '100%',
          minHeight: ['90%', null],
          flex: 1,
          backgroundColor: 'black',
          border: 'none',
        }}
      />
      {!authenticated ? (
        <Suspense.Boundary fallback={null}>
          <GamePopup />
        </Suspense.Boundary>
      ) : null}
    </>
  )
}

// For @loadable/components
export default GameLauncher
