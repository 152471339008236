import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {Banner} from './banner'
import {CasinoCatalog} from './casino-catalog'

export function LandingPage() {
  return (
    <ThemeUi.Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: [1, 2],
      }}
    >
      <Banner />
      <CasinoCatalog />
    </ThemeUi.Box>
  )
}

// For @loadable/components
export default LandingPage
